import React, {useCallback, useMemo} from 'react';
import {Button, Popover, ActionList} from '@shopify/polaris';
import { MenuVerticalIcon } from "@shopify/polaris-icons";

const PopoverAction = ({id, actions, popoverActive, togglePopoverActive}) => {

  /** Turns on/off actions in Popover */
  const handlePopoverActive = useCallback(
    (ruleId) => {
      if (popoverActive === ruleId) {
        togglePopoverActive(null);
      } else {
        togglePopoverActive(ruleId);
      }
    },
    [togglePopoverActive, popoverActive],
  );

  /**
   * @description Add popover closing function to actions
   * @returns {Array} - Array of objects - Action list items
   */
  const popoverActions = useMemo(() => {
    if (actions?.length) {
      return actions.map((item) => {
        return {
          ...item,
          onAction: () => {
            item.onAction(/*id*/);
            togglePopoverActive(null);
          }
        }
      });
    }
  }, [actions]);

  const activator = (
    <Button
      icon={MenuVerticalIcon}
      onClick={() => handlePopoverActive(id)}

      ariaHaspopup
      variant="plain" />
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        // id={id}
        id={'CustomStylePopover'}
        active={popoverActive === id}
        activator={activator}
        onClose={() => handlePopoverActive(null)}
        preferredAlignment='right'
        preferredPosition='below'
      >
        <Popover.Pane>
          <ActionList
            actionRole="menuitem"
            items={popoverActions}
          />
        </Popover.Pane>
      </Popover>
    </div>
  );
};

export default PopoverAction;